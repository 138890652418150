export default function useFormat(){
    const numberFormat = (amount, fraccion) => {
        const nAmount = amount ? Number(amount) : 0
        return nAmount.toLocaleString(undefined, {minimumFractionDigits: fraccion, maximumFractionDigits: fraccion})
    }

    const dateFormat = date => {
        return date ? date.split('-').reverse().join('/') : ''
    }

    const dateFormatHtml = (date) => {
        const fullDate = date.split(' ')
        return `
            <span>${fullDate[0].split('-').reverse().join('/')}</span><br>
            <span class="mt-2">${fullDate[1]}</span>
        `
    }

    return {
        numberFormat,
        dateFormat,
        dateFormatHtml
    }
}
