<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h4 class="card-title">Historial de Pagos</h4>
        </div>
      </div>
      <div class="card-body">
        <div class="table-wrapper-scroll-y my-custom-scrollbar">
          <table class="table">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Cantidad</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payment in payments" :key="payment.id">
                <td>{{ dateFormat(payment.date) }}</td>
                <td>${{ numberFormat(payment.amount, 2) }}</td>
                <td>
                  <span
                    :class="`badge badge-${
                      payment.status === 'pendiente' ? 'light' : 'primary'
                    }`"
                    >{{ payment.status }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useFormat from "@/composables/format/useFormat";
export default {
  props: {
    payments: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { dateFormat, numberFormat } = useFormat();

    return {
      dateFormat,
      numberFormat,
    };
  },
};
</script>
<style scoped>
.badge-warning-yellow {
  background-color: #ffc107;
}
.my-custom-scrollbar {
  position: relative;
  height: 650px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
