import { ref } from "@vue/reactivity";
import {
    getPaymentsService,
} from "../../services/payments/paymentsServices";
export default () => {
    const loading = ref(false);
    const data = ref([]);
    const getPayments = async () => {
        NProgress.start();
        loading.value = true;
        const response = await getPaymentsService();

        if (response.status === 200) {
            data.value = response.data;
        } else {
            Swal.fire("Error!", response.message, "error");
        }
        loading.value = false;
        NProgress.done();
    };
    return {
        loading,
        data,
        getPayments
    };
};
