<template>
  <bread-layout :items="breadcrumbLinks"></bread-layout>
  <div class="row">
    <AlertsPayment :nextPayments="getAuthUser?.client?.next_payment" />
  </div>
  <PaymentsTable :payments="data" />
</template>

<script>
import AlertsPayment from "@/views/profile/AlertsPayment.vue";
import { useStore } from "vuex";
import { ref, computed } from "@vue/reactivity";
import BreadLayout from "../../layouts/BreadLayout.vue";
import useGetPayments from "@/composables/payments/useGetPayments.js";
import PaymentsTable from "./PaymentsTable.vue";
import { onMounted } from 'vue-demi';
export default {
  components: { BreadLayout, PaymentsTable, AlertsPayment },
  setup() {
    onMounted(()=>{
           window.scrollTo( 0, 0 );
        })
    const breadcrumbLinks = ref([
      {
        text: "Pagos",
        path: "/pagos",
      },
    ]);
    const { getPayments, data } = useGetPayments();
    getPayments();

    const store = useStore();

    return {
      getAuthUser: computed(() => store.state.User.user),
      breadcrumbLinks,
      data,
    };
  },
};
</script>

<style>
</style>
